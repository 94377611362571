"use client"

import {
  createTRPCClient,
  httpBatchLink,
  httpLink,
  loggerLink,
  splitLink,
  unstable_httpSubscriptionLink,
} from "@trpc/react-query"

import type { AppRouter } from "@/server"
import { getDomain } from "@/utils/env"
import { MutationCache, QueryCache } from "@tanstack/react-query"
import { createTRPCNext } from "@trpc/next"
import { ssrPrepass } from "@trpc/next/ssrPrepass"

const apiUrl = `${getDomain()}/api/v1/trpc`

// TODO: check how to simplify this
const queryClientConfig = {
  queryCache: new QueryCache({
    onError: (error, query) => {
      // global error handler for queries
      console.error("QUERIES: GLOBAL REACT-QUERY QUERY ERROR", {
        error,
        query: query.queryKey,
      })
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables) => {
      // global error handler for mutations
      console.error("QUERIES: GLOBAL REACT-QUERY MUTATION ERROR", {
        error,
        variables,
      })
    },
  }),
}

export const trpc = createTRPCNext<AppRouter>({
  config(opts) {
    const { ctx } = opts
    if (typeof window !== "undefined") {
      // during client requests
      return {
        links: [
          loggerLink(),
          splitLink({
            // uses the httpSubscriptionLink for subscriptions
            condition: (op) => op.type === "subscription",
            true: unstable_httpSubscriptionLink({
              url: apiUrl,
            }),
            false: splitLink({
              // disable batching for certain operations
              condition: (op) => op.context.skipBatch === true,
              true: httpLink({
                url: apiUrl,
              }),
              false: httpBatchLink({
                url: apiUrl,
              }),
            }),
          }),
        ],
        // TODO: check if this config is still required
        queryClientConfig,
      }
    }

    // during server requests
    return {
      links: [
        loggerLink(),
        httpBatchLink({
          url: apiUrl,
          /**
           * Set custom request headers on every request from tRPC
           * @link https://trpc.io/docs/v10/header
           */
          headers() {
            if (!ctx?.req?.headers) {
              return {}
            }
            // To use SSR properly, you need to forward client headers to the server
            // This is so you can pass through things like cookies when we're server-side rendering
            return {
              cookie: ctx.req.headers.cookie,
            }
          },
        }),
      ],
    }
  },
  /**
   * @link https://trpc.io/docs/v11/ssr
   **/
  ssr: true,
  ssrPrepass,
})

export const trpcAsync = createTRPCClient<AppRouter>({
  links: [
    loggerLink(),
    splitLink({
      condition: (op) => op.type === "subscription",
      true: unstable_httpSubscriptionLink({
        url: apiUrl,
      }),
      false: httpBatchLink({
        url: apiUrl,
      }),
    }),
  ],
})
